<template>
  <div>
    <template v-if="!getIsLoadingVolumeDetails">
      <template v-if="hasVolumeDetails">
        <volume-details
          class="mt-10 mt-sm-2"
          :volume-details="getVolumeDetails"
          @finalizarPedido="finalizarPedido"
          @gerarSeparacao="gerarSeparacao"
          :separacaoValue="separacaoValue"
        />
      </template>
    </template>
    <default-spinner v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultSpinner from "@/shared/components/vuetify/DefaultSpinner.vue";
import VolumeDetails from "./VolumeDetails.vue";

export default {
  name: "TheListing",
  components: {
    DefaultSpinner,
    // PanelConfirmation,
    VolumeDetails,
  },
  data() {
    return {
      separacaoValue: 0,
    };
  },
  beforeDestroy() {
    this.resetVolumeDetails();
  },

  methods: {
    ...mapActions("separacaoPaineis", [
      "volumeDetails",
      "resetVolumeDetails",
      "finalizaPedido",
    ]),
    finalizarPedido() {
      const body = {
        numeroPedido: this.getVolumeDetails.numeroPedido,
        finalizarPedido: true,
      };

      this.finalizaPedido(body);
    },
    gerarSeparacao() {
      const body = {
        numeroPedido: this.getVolumeDetails.numeroPedido,
        finalizarPedido: false,
      };

      this.finalizaPedido(body);
    },
  },
  computed: {
    ...mapGetters("separacaoPaineis", [
      "getVolumeDetails",
      "getIsLoadingVolumeDetails",
    ]),
    hasVolumeDetails() {
      return Object.keys(this.getVolumeDetails).length > 0;
    },
  },
};
</script>

<style scoped></style>
