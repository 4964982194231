export default {
  setVolumeDetails(state, payload) {
    state.volumeDetails = payload;
  },
  setIsLoadingVolumeDetails(state, payload) {
    state.isLoadingVolumeDetails = payload;
  },
  setIsBlockOrderModalOpen(state, payload) {
    state.isBlockOrderModalOpen = payload;
  },
  setViewKey(state, payload) {
    state.viewKey = payload;
  },
  setIsInspectionCompletedModalOpen(state, payload) {
    state.isInspectionCompletedModalOpen = payload;
  },
  setSeparadores(state, payload) {
    state.separadores = payload;
  },
};
