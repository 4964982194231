<template>
  <div class="text--text">
    <v-row class="mx-auto base pa-3">
      <v-col>
        <v-col align="start" cols="12" class="py-1">
          <span class="d-block font-weight-bold"
            >Pedido<span class="primary--text ml-1 font-weight-bold">{{
              volumeDetails.numeroPedido
            }}</span></span
          >
        </v-col>
        <v-col align="start" cols="12" class="py-1">
          <span class="d-block font-weight-bold"
            >Cliente:
            <span class="font-weight-light">{{
              volumeDetails.clienteNome
            }}</span></span
          >
        </v-col>
        <v-col align="start" cols="12" class="py-1">
          <span class="d-block font-weight-bold"
            >Data de Produção:
            <span class="font-weight-light">{{
              volumeDetails.dataInicioSeparacao | dateDMY
            }}</span></span
          >
        </v-col>
        <v-col align="start" cols="12" class="py-1">
          <span class="d-block font-weight-bold"
            >Hora de Produção:
            <span class="font-weight-light">{{
              volumeDetails.dataInicioSeparacao | hourAndMinute
            }}</span></span
          >
        </v-col>
        <v-col align="start" cols="12" class="py-1">
          <span class="d-block font-weight-bold"
            >Transportadora:
            <span class="font-weight-light">{{
              volumeDetails.transportadoraNomeFantasia
            }}</span></span
          >
        </v-col>
      </v-col>
      <v-col>
        <v-col align="end" cols="12" class="py-1">
          <span class="d-block font-weight-bold"
            >Separação:
            <span class="primary--text font-weight-bold">{{
              volumeDetails.qtdSeparacoes
            }}</span></span
          >
        </v-col>
        <v-col align="start" cols="12" class="py-1">
          <span class="d-block font-weight-bold mr-1">Descrição do Kit: </span>
          <span class="font-weight-light">{{
            volumeDetails.kitDescricao
          }}</span>
        </v-col>
        <v-col align="start" cols="12" class="py-1">
          <span class="d-block font-weight-bold">Painéis:</span>
          <span
            v-for="painel in volumeDetails.paineis"
            class="font-weight-light"
            :key="painel"
            >{{ painel.descricao }}</span
          >
        </v-col>
      </v-col>
    </v-row>
    <v-row align="center" class="justify-center mx-auto base" v-show="false">
      <v-col cols="5"> </v-col>
    </v-row>
    <v-row align="center" class="justify-center mx-auto base">
      <v-col cols="3" class="mr-1">
        <default-button @click="finalizarPedido">
          Finalizar Pedido</default-button
        >
      </v-col>
      <v-col cols="3" class="ml-1">
        <default-button
          color="orange white--text"
          outlined
          @click="gerarSeparacao"
        >
          Gerar Separação</default-button
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";

export default {
  name: "VolumeDetails",
  components: {
    DefaultButton,
  },
  data() {
    return {
      isLoading: false,
      orderToBeConfirmed: null,
    };
  },
  props: {
    volumeDetails: {
      type: Object,
      required: true,
      default: () => {},
    },
    separacaoValue: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    allPanelsInspected() {
      const totalPanels = this.volumeDetails.qntPaineisPedido;
      const inspectedPanels = this.volumeDetails.qntPaineisConferido;
      return inspectedPanels === totalPanels && totalPanels > 0;
    },
    borderColor() {
      const isDarkModeOn = this.$vuetify.theme.dark;
      return isDarkModeOn ? "#383838" : "#dee2e6";
    },
  },
  methods: {
    confirmOrder() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    },
    openBlockOrderModal() {
      this.$emit("openBlockOrderModal");
    },
    finalizarPedido() {
      this.$emit("finalizarPedido");
    },
    gerarSeparacao() {
      this.$emit("gerarSeparacao");
    },
  },
  filters: {
    dateDMY(value) {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleDateString("pt-BR");
    },
    hourAndMinute(value) {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleTimeString("pt-BR").slice(0, 5);
    },
  },
};
</script>

<style scoped></style>
