export default {
  getCurrentMainTab(state) {
    return state.mainTabs[state.currentMainTabIndex];
  },
  getCurrentSubTab(state) {
    return state.mainTabs[state.currentMainTabIndex].subTabs[
      state.currentSubTabIndex
    ];
  },
  getUrlParams(state) {
    const mergedUrlParams = {
      ...state.urlParams.defaultParams,
      ...state.urlParams.filterParams,
    };
    return mergedUrlParams;
  },
  getFiltersParams(state) {
    return state.urlParams.filterParams;
  },
  getInputsByLine: (state) => (line, listsByVolume) => {
    const { inputDetails } = state;
    return listsByVolume
      ? inputDetails
      : inputDetails.filter((input) => input.linha === line);
  },
};
