<template>
  <v-container fluid class="pa-5" :key="'viewKey' + getViewKey">
    <the-header />
    <the-listing />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import TheHeader from "../components/TheHeader.vue";
import TheListing from "../components/TheListing.vue";
export default {
  name: "SeparacaoPaineis",
  computed: {
    ...mapGetters("separacaoPaineis", ["getViewKey"]),
  },
  components: {
    TheHeader,
    TheListing,
  },
  data: () => ({}),
};
</script>
<style></style>
