<template>
  <v-hover v-slot="{ hover }">
    <v-row
      v-bind="$attrs"
      :class="`text--text ma-auto my-2 ${
        hover ? 'hoverGlobal' : backgroundColor
      }`"
      align-md="center"
      :style="hideBorder ? '' : `border: 1px solid ${borderColor} !important`"
    >
      <slot></slot>
    </v-row>
  </v-hover>
</template>

<script>
export default {
  name: "DefaultTableBodyRow",
  props: {
    backgroundColor: {
      type: String,
      default: "base",
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    borderColor() {
      const isDarkModeOn = this.$vuetify.theme.dark;
      return isDarkModeOn ? "#383838" : "#dee2e6";
    },
  },
};
</script>

<style scoped></style>
