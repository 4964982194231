import Repository from "../repository";

export default {
  async volumeDetails({ commit }, body) {
    commit("setIsLoadingVolumeDetails", true);
    commit("setVolumeDetails", {});
    try {
      const response = await Repository.getVolumeDetails(body);
      if (response.status === 200) {
        const volumeDetails = response.data.data;
        commit("setVolumeDetails", volumeDetails);
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsLoadingVolumeDetails", false);
    }
  },
  async finalizaPedido({ commit }, body) {
    commit("setIsLoadingVolumeDetails", true);
    commit("setVolumeDetails", {});
    try {
      const response = await Repository.finalizaPedido(body);
      const message = body.finalizarPedido
        ? "Pedido Finalizado com sucesso"
        : "Separação Gerada com sucesso";
      if (response.status === 200) {
        const volumeDetails = response.data.data;
        let successObj = {
          successStatus: true,
          successMessage: message,
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        if (body.finalizarPedido) {
          commit("setVolumeDetails", []);
        } else {
          commit("setVolumeDetails", volumeDetails);
        }
        return;
      } else {
        commit(
          "notifications/setErrorNotification",
          { errorStatus: true, errorMessage: response.data.message },
          { root: true }
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsLoadingVolumeDetails", false);
    }
  },

  async listSeparadores({ commit }) {
    try {
      const response = await Repository.getSeparadores();
      if (response.status === 200) {
        const separadores = response.data;
        const mappedSeparadores = separadores.map((value) => {
          return {
            value: parseInt(value.user.PCEMPR_ID),
            text: value.user.empr.NOME,
          };
        });
        commit("setSeparadores", mappedSeparadores);
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    }
  },

  resetVolumeDetails({ commit }) {
    commit("setVolumeDetails", {});
  },
  setIsBlockOrderModalOpen({ commit }, payload) {
    commit("setIsBlockOrderModalOpen", payload);
  },
  setIsInspectionCompletedModalOpen({ commit }, payload) {
    commit("setIsInspectionCompletedModalOpen", payload);
  },
  reRenderView({ commit, state }) {
    commit("setViewKey", state.viewKey + 1);
  },
};
