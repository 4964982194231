const initialMainTabIndex = 0;
const initialSubTabIndex = 0;
const winthorOrder = "numeroPedido";
const storeOrder = "numeroPedidoWeb";
const startDate = "dataInicio";
const endDate = "dataFinal";
const sorter = "nomeSeparador";
const leader = "nomeBipador";
const checker = "nomeConferente";
const dateRange = {
  startDate,
  endDate,
};
const collaborators = [
  { label: "Separador", value: sorter },
  { label: "Líder", value: leader },
  { label: "Conferente", value: checker },
];
const sharedFilters = {
  winthorOrder,
  storeOrder,
  dateRange,
};

export default function getOriginalState() {
  return {
    listing: [],
    orderOverview: {},
    stageDuration: {},
    inputDetails: [],
    sortingDetails: [],
    isLoadingListing: false,
    currentMainTabIndex: initialMainTabIndex,
    currentSubTabIndex: initialSubTabIndex,
    pagination: {
      total: 0,
      perPage: 0,
      currentPage: 0,
      lastPage: 0,
      nextPage: 0,
    },
    urlParams: {
      defaultParams: {
        page: 1,
        perPage: 10,
      },
      filterParams: {},
    },
    mainTabs: [
      {
        label: "Painéis",
        isVisible: true,
        initialSubTabIndex,
        line: 1,
        subTabs: [
          {
            label: "Dados Gerais",
            endpoint: "/api/v2/lead-time/painel/dados-gerais",
            columns: [
              {
                label: "Pedido",
                items: [{ label: "Pedido Winthor" }, { label: "Pedido Loja" }],
              },
              {
                label: "Cliente",
                md: "3",
              },
              {
                label: "Quantidades",
                items: [{ label: "Painéis" }, { label: "Volumes" }],
              },
              {
                label: "Data",
              },
              {
                label: "Durações",
                items: [{ label: "Pedido" }, { label: "Volumes" }],
              },
              {
                label: "Botões",
                md: "1",
                buttons: [
                  { label: "Duração das Etapas" },
                  { label: "Detalhes de Insumos" },
                  { label: "Extrato" },
                ],
              },
            ],
            exclusiveFilters: {},
          },
          {
            label: "Separação",
            endpoint: "/api/v2/lead-time/painel/separacao",
            columns: [
              {
                label: "Pedido",
                items: [{ label: "Pedido Winthor" }, { label: "Pedido Loja" }],
              },
              {
                label: "Separadores",
                md: "4",
              },
              {
                label: "Separações",
              },
              {
                label: "Data",
              },
              {
                label: "Duração",
                md: "1",
              },
              {
                label: "Botões",
                md: 1,
                buttons: [{ label: "Extrato" }],
              },
            ],
            exclusiveFilters: {
              sorter,
            },
          },
          {
            label: "Bipagem",
            endpoint: "/api/v2/lead-time/painel/bipagem",
            listsByVolume: true,
            operationId: 1,
            columns: [
              {
                label: "Pedido",
                md: "3",
                items: [
                  { label: "Pedido Winthor" },
                  { label: "Pedido Loja" },
                  { label: "Volume" },
                ],
              },
              {
                label: "Líder",
                md: "3",
              },
              {
                label: "Quantidades",
                items: [{ label: "Painéis" }, { label: "Volumes" }],
              },
              {
                label: "Data",
              },
              {
                label: "Duração",
                md: "1",
              },
              {
                label: "Botões",
                md: "1",
                buttons: [
                  { label: "Detalhes de Insumos" },
                  { label: "Detalhes de Separação" },
                  { label: "Extrato" },
                ],
              },
            ],
            exclusiveFilters: {
              leader,
            },
          },
          {
            label: "Conferência",
            endpoint: "/api/v2/lead-time/painel/conferencia",
            listsByVolume: true,
            operationId: 7,
            columns: [
              {
                label: "Pedido",
                md: "3",
                items: [
                  { label: "Pedido Winthor" },
                  { label: "Pedido Loja" },
                  { label: "Volume" },
                ],
              },
              {
                label: "Conferente",
                md: "3",
              },
              {
                label: "Quantidades",
                items: [{ label: "Painéis" }, { label: "Volumes" }],
              },
              {
                label: "Data",
              },
              {
                label: "Duração",
                md: "1",
              },
              {
                label: "Botões",
                md: "1",
                buttons: [
                  { label: "Detalhes de Insumos" },
                  { label: "Detalhes de Separação" },
                  { label: "Extrato" },
                ],
              },
            ],
            exclusiveFilters: {
              checker,
            },
          },
        ],
        filters: {
          ...sharedFilters,
          collaborators,
        },
      },
      {
        label: "Cabos",
        line: 2,
        initialSubTabIndex,
        isVisible: true,
        subTabs: [
          {
            label: "Dados Gerais",
            endpoint: "/api/v2/lead-time/cabos/bipagem",
            columns: [
              {
                label: "Pedido",
                items: [{ label: "Pedido Winthor" }, { label: "Pedido Loja" }],
              },
              {
                label: "Cliente",
              },
              {
                label: "Separador",
              },
              {
                label: "Total (m)",
                md: "1",
              },
              {
                label: "Volumes",
                md: "1",
              },
              {
                label: "Data",
                md: "1",
              },
              {
                label: "Duração",
              },
              {
                label: "Botões",
                md: "1",
                buttons: [
                  { label: "Detalhes de Insumos" },
                  { label: "Extrato" },
                ],
              },
            ],
            exclusiveFilters: {},
          },
        ],
        filters: {
          ...sharedFilters,
          sorter,
        },
      },
      {
        label: "Materiais Diversos",
        line: 3,
        initialSubTabIndex,
        isVisible: true,
        subTabs: [
          {
            label: "Dados Gerais",
            endpoint: "/api/v2/lead-time/diversos/bipagem",
            listsByVolume: true,
            operationId: 3,
            columns: [
              {
                label: "Pedido",
                md: "3",
                items: [
                  { label: "Pedido Winthor" },
                  { label: "Pedido Loja" },
                  { label: "Volume" },
                ],
              },
              {
                label: "Cliente",
              },
              {
                label: "Separador",
              },
              {
                label: "Quantidades",
                items: [{ label: "SKUs" }, { label: "Insumos" }],
              },
              {
                label: "Data",
                md: "1",
              },
              {
                label: "Duração",
                md: "1",
              },
              {
                label: "Botões",
                md: "1",
                buttons: [
                  { label: "Detalhes de Insumos" },
                  { label: "Detalhes de Separação" },
                  { label: "Extrato" },
                ],
              },
            ],
            exclusiveFilters: {},
          },
        ],
        filters: {
          ...sharedFilters,
          sorter,
        },
      },
      {
        label: "Perfis",
      },
      {
        label: "Inversores",
      },
      {
        label: "Conferência AC",
      },
      {
        label: "Conferência Final",
      },
    ],
  };
}
