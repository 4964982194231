<template>
  <v-navigation-drawer
    app
    floating
    width="280"
    v-model="sidebarModel"
    :temporary="$vuetify.breakpoint.mdAndDown"
    class="base_dark"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="mt-2">
          <v-img
            :src="require('@/assets/logo_sou_serials_preta_01.svg')"
            class="justify-center"
            contain
            height="40"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list nav class="font-weight-bold">
      <v-list-item-group mandatory color="primary" :value="selectedMenuItem">
        <div v-for="(sidebarView, index) in shownSidebarViews" :key="index">
          <template v-if="!sidebarView.children">
            <v-list-item
              class="text-start align-self-center"
              :to="sidebarView.path"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>{{ sidebarView.meta.sidebarIcon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{
                sidebarView.meta.viewName ?? sidebarView.name
              }}</v-list-item-title>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-group
              :prepend-icon="sidebarView.meta.sidebarIcon"
              no-action
              :value="
                new RegExp(`^${sidebarView.path}/`).test($route.path + '/')
              "
            >
              <template v-slot:activator>
                <v-list-item-content class="ml-n6">
                  <v-list-item-title v-text="sidebarView.name" />
                </v-list-item-content>
              </template>

              <v-list-item-group>
                <div
                  v-for="(childView, index) in sidebarView.children"
                  :key="index"
                >
                  <v-list-item
                    class="pl-11 text-start"
                    dense
                    :to="sidebarView.path + '/' + childView.path"
                    v-if="userPermissions.includes(childView.meta.permission)"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="body-2 font-weight-normal">{{
                        childView.meta.viewName ?? childView.name
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list-item-group>
            </v-list-group>
          </template>
        </div>
        <v-list-item @click="actionLogout" class="mt-5">
          <v-list-item-icon class="mr-2 pl-0">
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-start"> Sair </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
let body = document.querySelector("body");
import { mapGetters, mapActions } from "vuex";
import { sidebarViews } from "../../router/index";
import { getUserLogged } from "../../services/userLogged";
import AuthRepository from "@/repositories/v2/AuthRepository";
import UsersRepository from "@/repositories/v1/UsersRepository";
import router from "../../router";

export default {
  components: {},
  name: "TheSidebar",
  data() {
    return {
      authRepository: AuthRepository.build(),
      usersRepository: UsersRepository.build(),
    };
  },
  created() {
    if (this.isBreakpointAtMdAndUp) {
      this.setDrawer(true);
    }
  },
  methods: {
    ...mapActions("menu", ["setDrawer"]),
    hideBodyScrollbar() {
      body.style.overflowY = "hidden";
    },
    showBodyScrollbar() {
      body.style.overflowY = "";
    },
    async actionLogout() {
      Object.keys(JSON.parse(JSON.stringify(localStorage))).forEach((l) =>
        localStorage.removeItem(l)
      );
      await router.push({ name: "Login" });
      this.authRepository.logout();
    },
  },
  computed: {
    ...mapGetters("menu", ["getDrawer"]),
    userPermissions() {
      return getUserLogged().permissoes;
    },
    sidebarViews() {
      return sidebarViews;
    },
    shownSidebarViews() {
      return sidebarViews.filter((sidebarView) => {
        const routeNeedsPermission = sidebarView.meta.permission ? true : false;
        const isRouteSubmenu = sidebarView.children ? true : false;
        if (isRouteSubmenu) {
          return sidebarView.children.some((child) =>
            this.userPermissions.includes(child.meta.permission)
          );
        } else {
          if (routeNeedsPermission) {
            return this.userPermissions.includes(sidebarView.meta.permission);
          }
          return true;
        }
      });
    },
    sidebarModel: {
      get() {
        return this.getDrawer;
      },
      set(newValue) {
        this.setDrawer(newValue);
      },
    },
    selectedMenuItem() {
      return this.shownSidebarViews.findIndex((sidebarView) =>
        new RegExp(`^${sidebarView.path}/`).test(this.$route.path + "/")
      );
    },
    isBreakpointAtMdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
};
</script>

<style scoped>
::v-deep .v-list-group__header {
  margin-bottom: 0 !important;
}
</style>
