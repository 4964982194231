<template>
  <v-dialog
    :width="width"
    data-vuetify
    v-model="dialogModel"
    :key="modalKey"
    :scrollable="scrollable"
  >
    <v-card
      data-vuetify
      class="text--text"
    >
      <div
        v-if="!hideHeaderClose"
        class="pb-5"
        :class="!!$slots.header ? 'background' : ''"
      >
        <v-icon
          size="28"
          color="primary"
          style="
            position: absolute !important;
            top: 4px !important;
            right: 4px !important;
          "
          @click="$emit('input', false)"
          >mdi-close-circle-outline</v-icon
        >
      </div>
      <div
        v-if="!!$slots.header"
        class="background"
      >
        <slot name="header"></slot>
        <v-divider />
      </div>
      <slot></slot>
      <div
        v-if="!!$slots.footer"
        justify="center"
        style="position: sticky; bottom: 0; overflow: hidden !important"
        class="background"
      >
        <v-divider />
        <slot name="footer"></slot>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DefaultModal",
  props: {
    value: {},
    width: {
      type: String,
      required: false,
      default: "500",
    },
    hideHeaderClose: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalKey: 0,
    };
  },
  computed: {
    dialogModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
        this.rerenderModal();
      },
    },
  },
  methods: {
    rerenderModal() {
      this.modalKey++;
    },
  },
};
</script>

<style scoped></style>
