<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    left
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        :style="disabled ? 'pointer-events: none;' : ''"
      >
        <v-text-field
          :label="label"
          :title="formatedDate"
          readonly
          dense
          :clearable="clearable"
          outlined
          :hide-details="hideDetails"
          flat
          :rules="rules"
          :value="formatedDate"
          background-color="base"
          @click:clear="
            [
              $emit('update:picker-date', $event.target.value),
              $emit('input', $event.target.value),
            ]
          "
          :disabled="disabled"
          :error="error"
          :error-messages="errorMessages"
          @input="$emit('input', $event)"
        >
        </v-text-field>
      </div>
    </template>
    <v-date-picker
      :key="reRender"
      color="primary"
      :value="pickerDate"
      @input="[$emit('update:picker-date', $event), (isMenuOpen = false)]"
      @change="$emit('input', $event)"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DefaultDatePicker",
  props: {
    pickerDate: {
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {},
  },
  data() {
    return {
      isMenuOpen: false,
      reRender: 0,
    };
  },
  computed: {
    formatedDate() {
      return this.pickerDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/, "$3/$2/$1");
    },
  },
  watch: {
    formatedDate(date) {
      if (date === "") {
        this.reRender += 1;
      }
    },
  },
};
</script>

<style scoped>
::v-deep input[type="text"],
input[type="tel"],
input[type="number"],
input[type="date"],
select,
textarea {
  all: inherit;
}
::v-deep legend {
  width: inherit;
}
::v-deep .v-text-field__slot {
  width: 100%;
  cursor: pointer;
}
::v-deep .v-input__icon,
.v-input__icon--clear {
  opacity: 1 !important;
}
</style>
