<template>
  <v-hover
    v-slot="{ hover }"
    :disabled="disableHover"
  >
    <v-btn
      v-on="$listeners"
      v-bind="$attrs"
      :color="color"
      :block="block"
      rounded
      :outlined="outlined"
      :class="outlined ? 'navy--text' : hover ? 'base--text' : 'navy--text'"
      class="font-weight-bold"
      :style="`text-transform: none; border-color: ${outlinedBorderColor}`"
    >
      <slot></slot>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: "DefaultButton",
  props: {
    isTransportadora: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    disableHover: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    outlinedBorderColor() {
      return this.$vuetify.theme.themes.light.primary;
    },
  },
};
</script>

<style scoped></style>
