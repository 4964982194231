import { HttpFileRequestService } from "@/services/http/HttpFileRequestService";
import { HttpRestService } from "@/services/http/HttpRestService";
export default class Repository {
  static getListing(endpoint, urlParams) {
    return HttpRestService.get(endpoint, urlParams);
  }
  static getOrderOverview(winthorOrder) {
    return HttpRestService.get(`/api/v2/lead-time/extrato/${winthorOrder}`);
  }
  static getStageDuration(winthorOrder) {
    return HttpRestService.get(
      `/api/v2/lead-time/painel/etapas/${winthorOrder}`
    );
  }
  static getInputDetails(code, listsByVolume) {
    const endpoint = listsByVolume
      ? `/api/v2/volume/insumos/detalhe/${code}`
      : `/api/v2/insumos/pedido/${code}/relacao-insumos-quantidade`;
    return HttpRestService.get(endpoint);
  }
  static getSortingDetails(orderId, operationId) {
    return HttpRestService.get(
      `/api/v2/lead-time/operacao/${orderId}/${operationId}`
    );
  }
  static generateOrdersReport(body, fileName, fileFormat) {
    return HttpFileRequestService.build()
      .setMethod("POST")
      .setUrl(`/api/v2/lead-time/painel/relatorio`)
      .setBody(body)
      .request(fileFormat, fileName);
  }
}
