<template>
  <v-form ref="form">
    <v-row dense :key="rowKey">
      <v-col>
        <v-row>
          <v-col cols="5">
            <div class="text-start mb-2">
              <span>Infome o Separador parceiro.</span>
            </div>
            <v-autocomplete
              label="Escolha um separador"
              v-model="chooseSeparador"
              :items="getSeparadores"
              outlined
              dense
              autofocus
              required
              clearable
              :hide-details="false"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="4" lg="4">
            <default-text-field
              v-show="chooseSeparador"
              @keydown.enter.prevent="searchVolume(volumeCode)"
              label="N° do Pedido"
              ref="volumeCode"
              v-model="volumeCode"
              id="volumeCode"
              autofocus
              :rules="[rules.required]"
              :hide-details="false"
            ></default-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="2" lg="2">
            <default-button
              @click="searchVolume(volumeCode)"
              class="rounded-lg"
              v-if="chooseSeparador"
            >
              <v-icon class="mr-2">mdi-magnify</v-icon>
              Pesquisar</default-button
            >
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4">
            <default-text-field
              v-if="chooseSeparador && hasVolumeDetails && volumeCode"
              @keypress.enter.prevent="searchBarCode(barCode)"
              label="Código de Barras"
              v-model="barCode"
              autofocus
              ref="barCode"
              id="barCode"
              :hide-details="false"
            ></default-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultTextField from "../../../shared/components/vuetify/DefaultTextField.vue";
import { getUserLogged } from "../../../services/userLogged";

export default {
  name: "TheHeader",
  components: {
    DefaultButton,
    DefaultTextField,
  },
  data() {
    return {
      volumeCode: "",
      chooseSeparador: 0,
      barCode: "",
      rowKey: 0,
    };
  },
  mounted() {
    this.listSeparadores();
  },
  methods: {
    ...mapActions("separacaoPaineis", [
      "volumeDetails",
      "listSeparadores",
      "finalizaPedido",
    ]),
    async searchVolume(volumeCode) {
      if (this.$refs.form.validate()) {
        const body = {
          numeroPedido: volumeCode,
          separador1PcEmpId: this.separador,
          separador2PcEmpId: this.chooseSeparador,
        };
        await this.volumeDetails(body);
        this.focusBarCodeInput();
      }
    },
    searchBarCode(barCode) {
      console.log(barCode);
      switch (barCode) {
        case "finalizarPedido":
          this.closePedido();
          break;
        case "GerarSeparacao":
          this.gerarSeparacao();
          break;
        default:
          console.log("default");
          break;
      }
    },
    async closePedido() {
      const body = {
        numeroPedido: this.volumeCode,
        finalizarPedido: true,
      };
      await this.finalizaPedido(body).then(() => {
        this.clearVolumeData();
      });
    },
    async gerarSeparacao() {
      const body = {
        numeroPedido: this.volumeCode,
        finalizarPedido: false,
      };
      await this.finalizaPedido(body);
    },
    clearVolumeData() {
      this.volumeCode = "";
      this.barCode = "";
      this.rowKey++;
      // this.$nextTick(() => {
      //   this.focusVolumeCodeInput();
      //   this.$refs.volumeCode.focus();
      // });
    },
    focusBarCodeInput() {
      this.$nextTick(() => {
        const barCodeInput =
          this.$refs.barCode?.$el || document.getElementById("barCode");
        if (barCodeInput) {
          barCodeInput.focus();
        }
      });
    },
    focusVolumeCodeInput() {
      const volumeCodeInput = this.$refs.volumeCode?.$el;
      if (volumeCodeInput) {
        volumeCodeInput.focus();
      }
    },
  },
  computed: {
    ...mapGetters("separacaoPaineis", [
      "getIsLoadingVolumeDetails",
      "getSeparadores",
      "getVolumeDetails",
    ]),
    hasVolumeDetails() {
      return Object.keys(this.getVolumeDetails).length > 0;
    },
    rules() {
      return {
        required: (input) => !!input || "Campo obrigatório",
      };
    },
    separador() {
      return parseInt(getUserLogged().pcEmprId);
    },
  },
  watch: {
    // volumeCode(newValue) {
    //   if (newValue) {
    //     this.searchVolume(newValue);
    //     this.focusBarCodeInput();
    //   }
    // },
    // barCode(newValue) {
    //   if (newValue) {
    //     this.searchBarCode(newValue);
    //     this.$refs.volumeCode.focus();
    //   }
    // },
  },
};
</script>

<style scoped></style>
