import Vue from "vue";

Vue.filter("formatPerfil", (perfil) => {
  const perfis = {
    administrador: "Administrador",
    supervisor: "Supervisor",
    "analista-de-produção": "Analista de Produção",
    "separador-painel": "Separador de Painéis",
    "separador-cabo": "Separador de Cabos",
    "separador-diversos": "Separador de Diversos",
    "separador-inversor": "Separador de Inversores",
    "separador-perfil": "Separador de Perfis",
    "conferente-de-diversos": "Conferente de Diversos",
    "conferente-triagem": "Conferente de Triagem",
    "auditor-de-triagem": "Auditor de Triagem",
    "conferente-final": "Conferente Final",
    "auxiliar-de-logistica": "Auxiliar de Logística",
    "bipador-painel": "Bipador de Painéis",
    transportador: "Transportador",
    "analista-de-processo": "Analista de Processo",
  };
  return perfis[perfil] ?? perfil;
});

Vue.filter("dateDMY", function (date) {
  if (date instanceof Date) {
    let [year, month, day, hours, minutes, seconds] = [
      date.getFullYear(),
      date.getMonth(),
      date.getDay(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ];
    const f = (d) => (d < 10 ? `0${d}` : d);
    return `${f(day)}/${f(month)}/${year} ${hours}:${minutes}:${seconds}`;
  }
  try {
    const dateStr = date.match(/\d{4}-\d{2}-\d{2}/)[0];
    const dateArr = dateStr.split("-");
    const timeStr = date.match(/\d{2}:\d{2}:\d{2}/)[0];
    const timeArr = timeStr.split(":");
    return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]} - ${timeArr[0]}:${timeArr[1]}`;
  } catch (e) {
    return "";
  }
});

Vue.filter("formatOrderStatus", function (orderStatus) {
  const orderStatuses = {
    PRODUZINDO: "PEDIDO EM PRODUÇÃO",
    CONCLUIDO: "PEDIDO CONCLUÍDO",
  };
  return orderStatuses[orderStatus] ?? orderStatus;
});

Vue.filter("formatBayStatus", function (bayStatus) {
  const bayStatuses = {
    TRANSPORTADO: "PALETE NA BAIA",
    PENDENTE: "AGUARDANDO PALETE",
  };
  return bayStatuses[bayStatus] ?? bayStatus;
});

Vue.filter("formatVolume", (volume) => {
  const orderWithoutVolume = volume.slice(0, -4);
  const volumeWithoutOrder = volume.slice(-4);
  return `${orderWithoutVolume}<span class="text-decoration-underline" style="text-underline-offset: 2px;">${volumeWithoutOrder}</span>`;
});
