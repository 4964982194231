<template>
  <v-tooltip
    :left="left"
    :top="top"
    :disabled="disableTooltip"
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-btn
          v-on="{ ...on, ...$listeners }"
          v-bind="{ ...attrs, ...$attrs }"
          fab
          :elevation="depressed ? '' : '2'"
          :small="small"
          :color="color"
          :class="hover ? 'base--text' : 'navy--text'"
        >
          <slot></slot>
        </v-btn>
      </v-hover>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "DefaultFab",
  props: {
    small: {
      type: Boolean,
      default: true,
    },
    top: {
      type: Boolean,
      default: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    tooltipText: {
      type: String,
      required: false,
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
    depressed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
